import axios from "axios";

class AppSetting {
  vueInstance = null;
  isCollapse = false;
  globalConfig = null;
  loginWindow = null;
  exemptionBackendPaths = null;
  exemptionPageNames = null;

  constructor() {
    this.isCollapse = false;
    this.exemptionBackendPaths = [
      "/login",
      "/login/exchangeAccessToken",
      "/qrcode",
      "/forgetPassword/sendVerificationCode",
      "/forgetPassword/verifyCode",
      "/forgetPassword/resetPassword",
    ];
    this.exemptionPageNames = ["login", "qrcode", "forgetPassword"];
  }

  async init() {
    try {
      let response = await axios.get("common/GetGlobalConfig", {
        baseURL: this.getAPIRoot(),
        timeout: this.getConnectionTimeout(),
      });

      let res = response.data;
      if (res.result && res.code === "200") {
        this.globalConfig = res.result;
      }
    } catch {
      // ignore
    }
  }

  isProd() {
    return process.env.NODE_ENV === "production";
  }

  getExemptionBackendPaths() {
    return this.exemptionBackendPaths;
  }

  getExemptionPageNames() {
    return this.exemptionPageNames;
  }

  getRole() {
    return process.env.VUE_APP_ROLE;
  }

  getAPIRoot() {
    return process.env.VUE_APP_ROOT_API;
  }

  getConnectionTimeout() {
    return parseInt(process.env.VUE_APP_API_TIMEOUT);
  }

  getSignIn() {
    return process.env.VUE_APP_SIGN_IN;
  }

  getSignOut() {
    return process.env.VUE_APP_SIGN_OUT;
  }

  getGlobalConfig() {
    if (this.globalConfig == null) {
      this.init();
    }
    return this.globalConfig;
  }

  getGenericValue(key, defaultValue = null) {
    let gc = this.getGlobalConfig();
    let keyValues = gc.keyValues || { items: [] };
    let kv = keyValues.items.find((e) => e.key === key);

    return kv ? kv.value : defaultValue;
  }
}

export default new AppSetting();
