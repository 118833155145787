<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i class="el-icon">
                <component v-bind:is="item.icon"></component>
              </i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{
                subItem.title
              }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-if="item.role.indexOf(this.$user.getUserRole()) >= 0">
          <el-menu-item :index="item.index" :key="item.index">
            <i class="el-icon">
              <component v-bind:is="item.icon"></component>
            </i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "home-filled",
          index: "dashboard",
          title: "JE Tree",
          role: [2048, 512],
        },
        {
          icon: "user-filled",
          index: "studentList",
          title: "Students",
          role: [2048],
        },
        {
          icon: "reading",
          index: "courseList",
          title: "Courses",
          role: [2048],
        },
        {
          icon: "notebook",
          index: "lessonList",
          title: "Lessons",
          role: [2048],
        },
        {
          icon: "house",
          index: "classRoomList",
          title: "ClassRooms",
          role: [2048],
        },
        {
          icon: "dataLine",
          index: "weeklyReportList",
          title: "Weekly Reports",
          role: [2048],
        },
        {
          icon: "histogram",
          index: "testReportList",
          title: "Term Reports",
          role: [2048],
        },
        {
          icon: "money",
          index: "receiptList",
          title: "Receipts",
          role: [2048],
        },
        {
          icon: "ScaleToOriginal",
          index: "transactionList",
          title: "Transaction Records",
          role: [2048],
        },
        {
          icon: "coin",
          index: "pointsRecordList",
          title: "Points Issued List",
          role: [2048],
        },
        {
          icon: "user",
          index: "userList",
          title: "Users",
          role: [2048],
        },
        {
          icon: "postcard",
          index: "emailTemplateList",
          title: "Email Templates",
          role: [2048],
        },
        {
          icon: "message",
          index: "sendingEmail",
          title: "Sending Email",
          role: [2048],
        },
        {
          icon: "postcard",
          index: "notificationTemplateList",
          title: "Notification Templates",
          role: [2048],
        },
        {
          icon: "bell",
          index: "notificationList",
          title: "Post Notification",
          role: [2048],
        },
        {
          icon: "ChatLineSquare",
          index: "forumMessageList",
          title: "Forum Message",
          role: [2048],
        },
        {
          icon: "BellFilled",
          index: "studentSubscriptionList",
          title: "Student Subscription",
          role: [2048],
        },
        {
          icon: "setting",
          index: "settings",
          title: "Global Settings",
          role: [2048],
        },
        {
          icon: "monitor",
          index: "userLogs",
          title: "User Logs",
          role: [2048],
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path;
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
  background-color: #324157;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 180px;
}
.sidebar > ul {
  height: 100%;
}
</style>
