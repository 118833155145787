import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      pointsData: { points: 0, pendingPoints: 0, creditPoints: 0 },
      userProfileUrl: "",
      showTopUpDialog: false,
      showGlobalFloater: false,
      tagsList: [],
      collapse: false,
      inMiniScreen: false,
      windowWidth: 0,
      windowHeight: 0,
    };
  },
  mutations: {
    delTagsItem(state, data) {
      state.tagsList.splice(data.index, 1);
    },
    setTagsItem(state, data) {
      state.tagsList.push(data);
    },
    clearTags(state) {
      state.tagsList = [];
    },
    closeTagsOther(state, data) {
      state.tagsList = data;
    },
    closeCurrentTag(state, data) {
      for (let i = 0, len = state.tagsList.length; i < len; i++) {
        const item = state.tagsList[i];
        if (item.path === data.$route.fullPath) {
          if (i < len - 1) {
            data.$router.push(state.tagsList[i + 1].path);
          } else if (i > 0) {
            data.$router.push(state.tagsList[i - 1].path);
          } else {
            data.$router.push("/");
          }
          state.tagsList.splice(i, 1);
          break;
        }
      }
    },
    handleCollapse(state, data) {
      state.collapse = data;
    },
    handleWindowSize(state, data) {
      state.inMiniScreen = data[0];
      state.windowWidth = data[1];
      state.windowHeight = data[2];
    },
    handleShowTopUpDialog(state, data) {
      state.showTopUpDialog = data;
    },
    handleShowGlobalFloater(state, data) {
      state.showGlobalFloater = data;
    },
    handlePointsDataChange(state, pointsData) {
      console.log("handlePointsDataChange", state, pointsData);
      state.pointsData = pointsData;
    },
    handleUserProfileUrlChange(state, userProfileUrl) {
      console.log("handleUserProfileUrlChange", state, userProfileUrl);
      state.userProfileUrl = userProfileUrl;
    },
  },
  actions: {},
  modules: {},
});
