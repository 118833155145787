<template>
  <div class="header">
    <div class="collapse-btn" @click="collapseChange">
      <el-icon v-if="!collapse">
        <fold />
      </el-icon>
      <el-icon v-else>
        <expand />
      </el-icon>
    </div>
    <div class="logo">JE Education Management</div>
    <div class="header-right">
      <div class="header-user-con">
        <!--notification-->
        <el-popover :width="this.$widthRatio * 400 + 'px'" trigger="click">
          <template #reference>
            <el-badge :hidden="getUnreadNotificationCount() <= 0" :value="getUnreadNotificationCount()" :max="99">
              <el-icon>
                <Bell style="width: 32px; height: 32px; margin: 2px 0 0 -18px" />
              </el-icon>
            </el-badge>
          </template>
          <el-collapse v-model="activeNames" @change="handleNotificationClick">
            <el-collapse-item v-for="(item, index) in notificationList" :title="item.subject" :name="item" :key="index">

              <template #title>
                <span :class="item.status == 4 ? 'unread_dot' : 'read_dot'"></span>
                {{ item.subject.substring(0, 42) }}
                <span class="time_block">{{
                  this.$formatter.smartFormatDate(item.updatedTime)
                }}</span>
              </template>
              <div v-html="item.body"></div>
              <component v-bind:is="item.eventComponent"></component>
            </el-collapse-item>
          </el-collapse>
        </el-popover>
        <!-- user picture -->
        <div class="user-avator">
          <img src="../../assets/img/img.jpg" />
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleUserActions">
          <span class="el-dropdown-link">
            {{ username }}
            <el-icon>
              <arrow-down />
            </el-icon>
          </span>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="logOut"> Sign Out </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
  <el-dialog title="Session expired warning!" v-model="showSessionExpiryAlert" :close-on-click-modal="false"
    :close-on-press-escape="false" :show-close="false" width="420px">
    <el-row>
      Your session is already expired! before you quit out this page, you can click Login
      button to get the latest session and then continue on your work.
    </el-row>
    <el-row class="buttonLayer">
      <el-button type="warning" @click="gotoLogin">Login</el-button>
    </el-row>
  </el-dialog>
  <common :isForAdmin="true"></common>
</template>

<script>
import { postData } from "../../service/api/index";
import Common from "../common/Common.vue";

export default {
  components: { Common },
  data() {
    return {
      name: "header",
      controllerUrl: "/login",
      showSessionExpiryAlert: false,
      notificationList: [],
    };
  },
  async created() {
    // after dom updated, set isLoading to false
    this.$nextTick(async function () {
      this.notificationList = await this.$user.getNotifications();
    });
  },
  mounted() {
  },
  computed: {
    username() {
      let username = this.$user.getUsername();
      return username ? username : this.name;
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
  methods: {
    collapseChange() {
      this.$store.commit("handleCollapse", !this.collapse);
    },
    gotoLogin() {
      let route = this.$router.resolve({ path: "/login" });
      this.$appSetting.loginWindow = window.open(route.href, "_blank");
    },
    handleNotificationClick(notificationList) {
      console.log("handleClick", notificationList, this.activeNames);
      for (let i = 0; i < notificationList.length; i++) {
        const notification = notificationList[i];
        if (notification.status === 4) {
          notification.status = 64;
          postData(
            "notification/mark",
            {
              recordList: [
                {
                  notificationId: notification.notificationId,
                  receiverId: this.$user.getUserId(),
                  receiverName: this.$user.getUsername(),
                  status: notification.status,
                },
              ],
            },
            {
              isShowLoading: false,
            }
          ).then((res) => {
            if (res.result && res.code === "200") {
              console.log("Mark notification successfully");
            } else {
              this.$message.error(
                "Mark notification failed, error message: " + res.message
              );
            }
          });
        }
      }
    },
    getUnreadNotificationCount() {
      return this.notificationList.filter((e) => e.status == 4).length;
    },
    handleUserActions(command) {
      switch (command) {
        case "logOut":
          this.$emitter.emit("SIGN_OUT");
          break;
      }
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 1.8rem;
  color: #fff;
}

.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}

.header .logo {
  float: left;
  width: 400px;
  line-height: 70px;
  font-size: 2.4rem;
}

.header-right {
  float: right;
  padding-right: 50px;
}

.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 2.4rem;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.el-dropdown-menu__item {
  text-align: center;
}

.buttonLayer {
  margin-top: 20px;
}

.buttonLayer button {
  margin-left: auto;
}

.el-dropdown-menu__item {
  display: block;
}

.read_dot,
.unread_dot {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.read_dot {
  background-color: transparent;
}

.time_block {
  font-size: 0.84rem;
  color: gray;
  margin-left: 4px;
  line-height: 16px;
}
</style>
