<template>
    <div v-if="this.isForAdmin" class="postcard-container">
        <div class="user-face">
            <el-avatar fit="contain" shape="square" :size="294" :src="this.userRef.getUserProfileUrl(this.userId)" />
        </div>
        <div class="user-detials">
            <el-descriptions :column="1" border>
                <el-descriptions-item v-if="this.isForAdmin" label="Role" label-align="right" align="center">
                    {{ this.isStudent ? "Student" : "Admin" }}
                </el-descriptions-item>
                <el-descriptions-item v-if="this.isForAdmin && this.isStudent" label="Student No." label-align="right"
                    align="center">
                    {{ userData.studentId }}
                </el-descriptions-item>
                <el-descriptions-item label="Username" label-align="right" align="center" label-class-name="my-label"
                    class-name="my-content" width="150px">
                    {{ userData.username }}
                </el-descriptions-item>
                <el-descriptions-item v-if="this.isForAdmin && this.isStudent" label="School" label-align="right"
                    align="center" label-class-name="my-label" class-name="my-content" width="150px">
                    {{ userData.school }}
                </el-descriptions-item>
                <el-descriptions-item v-if="this.isForAdmin" label="Mobile" label-align="right" align="center">
                    {{ userData.mobileNo }}
                </el-descriptions-item>
                <el-descriptions-item v-if="this.isForAdmin" label="Email" label-align="right" align="center">
                    {{ userData.email }}
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
    <div v-else class="postcard-container">
        <div>
            <div><img :src="this.userRef.getUserProfileUrl(this.userId)" style="width:255px;" /></div>
            <div>{{ userData.username }}</div>
        </div>
    </div>
</template>

<script>
import { getData } from "../../service/api";
import { ElAvatar, ElDescriptions, ElDescriptionsItem } from "element-plus";

export default {
    components: {
        ElAvatar, ElDescriptions, ElDescriptionsItem,
    },
    props: {
        userId: String,
        isForAdmin: Boolean,
        userRef: Object,
    },
    data() {
        return {
            controllerUrl: "/user",
            isStudent: true,
            userData: {},
        }
    },
    created() {
        this.$nextTick(async function () {
            this.loadUserData();
        });
    },
    methods: {
        loadUserData() {
            let url = null;
            if (this.userId.length === 8) {
                this.isStudent = true;
                url = `/student/postcard/${this.userId}`;
            } else {
                this.isStudent = false;
                url = `/user/postcard/${this.userId}`;
            }
            getData(url).then((res) => {
                if (res.result && res.code === "200") {
                    this.userData = res.result;
                }
            });
        },
    },
}
</script>

<style scoped>
.postcard-container {
    display: flex;
    width: 100%;
}

.postcard-container .user-face {
    width: 30%;
    padding-right: 20px;
}

.postcard-container .user-detials {
    width: 70%;
}
</style>