var objectExtensions = {};
objectExtensions.init = function () {
  Number.prototype.toStringWithOrdinal = function () {
    var n = this;
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };
  Number.prototype.toStringWithOrdinalSup = function () {
    var n = this;
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + `<sup>${s[(v - 20) % 10] || s[v] || s[0]}</sup>`;
  };

  String.prototype.rtrim = function (s) {
    return this.replace(new RegExp(s + "*$"), "");
  };
  String.prototype.ltrim = function (s) {
    return this.replace(new RegExp(`^(${s})*`), "");
  };
  String.prototype.newLineReplace = function () {
    return this.replace(/\r?\n|\r/g, ' <br>');
  };
  String.equal = function (s1, s2, ignoreCase, useLocale) {
    if (s1 == null || s2 == null)
      return false;

    if (!ignoreCase) {
      if (s1.length !== s2.length)
        return false;

      return s1 === s2;
    }

    if (useLocale) {
      if (useLocale.length)
        return s1.toLocaleLowerCase(useLocale) === s2.toLocaleLowerCase(useLocale)
      else
        return s1.toLocaleLowerCase() === s2.toLocaleLowerCase()
    }
    else {
      if (s1.length !== s2.length)
        return false;

      return s1.toLowerCase() === s2.toLowerCase();
    }
  }

  CanvasRenderingContext2D.prototype.roundRect = function (
    x,
    y,
    width,
    height,
    radius = 5,
    fill = false,
    stroke = true
  ) {
    let ctx = this;
    if (typeof radius === "number") {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  };
  CanvasRenderingContext2D.prototype.fillMultiText = function (
    x,
    y,
    lines,
    lineHeight
  ) {
    let ctx = this;
    let line = "";
    for (let i = 0; i < lines.length; i++) {
      line = lines[i];
      ctx.fillText(line, x, y);
      y += lineHeight;
    }
  };
  CanvasRenderingContext2D.prototype.trim = function (threshold = 0) {
    let canvas = this.canvas;
    let ctx = canvas.getContext("2d"),
      w = canvas.width,
      h = canvas.height,
      imageData = ctx.getImageData(0, 0, w, h),
      tlCorner = { x: w + 1, y: h + 1 },
      brCorner = { x: -1, y: -1 };

    for (let y = 0; y < h; y++) {
      for (let x = 0; x < w; x++) {
        if (imageData.data[(y * w + x) * 4 + 3] > threshold) {
          tlCorner.x = Math.min(x, tlCorner.x);
          tlCorner.y = Math.min(y, tlCorner.y);
          brCorner.x = Math.max(x, brCorner.x);
          brCorner.y = Math.max(y, brCorner.y);
        }
      }
    }

    let cut = ctx.getImageData(
      tlCorner.x,
      tlCorner.y,
      brCorner.x - tlCorner.x,
      brCorner.y - tlCorner.y
    );

    canvas.width = brCorner.x - tlCorner.x;
    canvas.height = brCorner.y - tlCorner.y;

    ctx.putImageData(cut, 0, 0);

    return {
      width: canvas.width,
      height: canvas.height,
      x: tlCorner.x,
      y: tlCorner.y,
    };
  };
  CanvasRenderingContext2D.prototype.trimBottom = function (threshold = 0) {
    let canvas = this.canvas;
    let ctx = canvas.getContext("2d"),
      w = canvas.width,
      h = canvas.height,
      imageData = ctx.getImageData(0, 0, w, h),
      brCorner = { x: -1, y: -1 };

    for (let y = 0; y < h; y++) {
      for (let x = 0; x < w; x++) {
        if (imageData.data[(y * w + x) * 4 + 3] > threshold) {
          brCorner.y = Math.max(y, brCorner.y);
        }
      }
    }

    let cut = ctx.getImageData(0, 0, w, brCorner.y);

    canvas.width = w;
    canvas.height = brCorner.y;

    ctx.putImageData(cut, 0, 0);

    return {
      width: canvas.width,
      height: canvas.height,
      x: 0,
      y: 0,
    };
  };
  Object.defineProperty(Array.prototype, "orderBy", {
    value: function (sorts) {
      sorts.map((sort) => {
        sort.uniques = Array.from(new Set(this.map((obj) => obj[sort.key])));

        sort.uniques = sort.uniques.sort((a, b) => {
          if (typeof a == "string") {
            return sort.inverse ? b.localeCompare(a) : a.localeCompare(b);
          } else if (typeof a == "number") {
            return sort.inverse ? b - a : a - b;
          } else if (typeof a == "boolean") {
            let x = sort.inverse
              ? a === b
                ? 0
                : a
                  ? -1
                  : 1
              : a === b
                ? 0
                : a
                  ? 1
                  : -1;
            return x;
          }
          return 0;
        });
      });

      const weightOfObject = (obj) => {
        let weight = "";
        sorts.map((sort) => {
          let zeroPad = `${sort.uniques.length}`.length;
          weight += sort.uniques
            .indexOf(obj[sort.key])
            .toString()
            .padStart(zeroPad, "0");
        });
        //obj.weight = weight; // if you need to see weights
        return weight;
      };

      this.sort((a, b) => {
        return weightOfObject(a).localeCompare(weightOfObject(b));
      });

      return this;
    },
  });
};
export default objectExtensions;
