import { Base64 } from "js-base64";
import AppSetting from "../utils/appSetting";
import { h } from "vue";
import axios from "axios";
import { ElButton } from "element-plus";

class UserSession {
  _appId = null;
  _userId = null;
  _username = null;
  _userProfile = null;
  _userRole = null;
  _accessToken = null;
  _refreshToken = null;
  _signInDateTime = null;
  _expiredMinutes = null;
  _expiredMinutesForRefreshToken = null;
  _sessionName = `${AppSetting.getRole()}_je_user`;
  _profile = {};

  constructor(
    appId,
    userId,
    username,
    userProfile,
    userRole,
    accessToken,
    refreshToken,
    expiredMinutes,
    expiredMinutesForRefreshToken,
    signInDateTime,
    profile
  ) {
    this._appId = appId;
    this._userId = userId;
    this._username = username;
    this._userProfile = userProfile;
    this._userRole = userRole;
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
    this._expiredMinutes = expiredMinutes;
    this._expiredMinutesForRefreshToken = expiredMinutesForRefreshToken;
    this._signInDateTime = signInDateTime;
    this._profile = profile || {};
  }

  currentUser() {
    let b64 = localStorage.getItem(this._sessionName);
    if (b64) {
      try {
        let json = Base64.decode(b64);
        let obj = JSON.parse(json);
        // let obj = JSON.parse(b64);
        return new UserSession(
          obj._appId,
          obj._userId,
          obj._username,
          obj._userProfile,
          obj._userRole,
          obj._accessToken,
          obj._refreshToken,
          obj._expiredMinutes,
          obj._expiredMinutesForRefreshToken,
          obj._signInDateTime,
          obj._profile
        );
      } catch {
        localStorage.removeItem(this._sessionName);
      }
    }
    return new UserSession("", "", "", "", 0, 0);
  }

  getAppId() {
    return this.currentUser()._appId || "";
  }

  getAccessToken() {
    return this.currentUser()._accessToken || "";
  }

  getRefreshToken() {
    return this.currentUser()._refreshToken || "";
  }

  getUserId() {
    return this.currentUser()._userId || "";
  }

  getUsername() {
    return this.currentUser()._username || "";
  }

  refreshProfileUrl() {
    let user = this.currentUser();
    user._profileUrl = user._buildProfileUrl(user._username, user._accessToken);
    this.saveSession(user);
    return user._profileUrl;
  }

  getProfileUrl() {
    let profileUrl = this.currentUser()._profileUrl;
    if (!profileUrl) {
      profileUrl = this.refreshProfileUrl();
    }
    console.log("getProfileUrl", profileUrl);
    return profileUrl;
  }

  getUserRole() {
    return this.currentUser()._userRole || "";
  }

  async refreshPoints() {
    let response = await axios.get(`points/student/${this.getUsername()}`, {
      baseURL: AppSetting.getAPIRoot(),
      timeout: AppSetting.getConnectionTimeout(),
      headers: { Authorization: "Bearer " + this.getAccessToken() },
    });
    let res = response.data;
    if (res.result && res.code === "200") {
      AppSetting.vueInstance.config.globalProperties.$store.commit(
        "handlePointsDataChange",
        res.result
      );
    }
  }

  signIn(
    appId,
    userId,
    username,
    userRole,
    accessToken,
    refreshToken,
    expiredMinutes,
    expiredMinutesForRefreshToken
  ) {
    try {
      let profileUrl = this._buildProfileUrl(username, accessToken);

      this.saveSession(
        new UserSession(
          appId,
          userId,
          username,
          profileUrl,
          userRole,
          accessToken,
          refreshToken,
          expiredMinutes,
          expiredMinutesForRefreshToken,
          new Date().getTime(),
          {}
        )
      );
      this.refreshPoints();
    } catch {
      localStorage.removeItem(this._sessionName);
    }
  }

  signOut() {
    localStorage.removeItem(this._sessionName);
    this._appId = "";
    this._userId = "";
    this._username = "";
    this._profileUrl = "";
    this._userRole = "";
    this._accessToken = "";
    this._refreshToken = "";
    this._expiredMinutes = 0;
    this._expiredMinutesForRefreshToken = 0;
    this._signInDateTime = 0;
  }

  async tryExchangeAccessToken() {
    try {
      let user = this.currentUser();
      if (
        !(
          user._appId &&
          user._userId &&
          user._username &&
          user._userRole &&
          user._accessToken &&
          user._accessToken.length == 22 &&
          user._refreshToken &&
          user._refreshToken.length == 22
        )
      ) {
        return "EXPIRY";
      }

      let response = await axios.post(
        "login/exchangeAccessToken",
        {
          appId: user._appId,
          accessToken: user._accessToken,
          refreshToken: user._refreshToken,
        },
        {
          baseURL: AppSetting.getAPIRoot(),
          timeout: AppSetting.getConnectionTimeout(),
        }
      );

      let res = response.data;
      if (res.result && res.code === "200") {
        this.signIn(
          user._appId,
          user._userId,
          user._username,
          user._userRole,
          res.result.accessToken,
          res.result.refreshToken,
          res.result.accessTokenExpiredInMinutes,
          res.result.refreshTokenExpiredInMinutes
        );
        return "OK";
      }
    } catch {
      // ignore
    }
    return "EXPIRY";
  }

  saveSession(user) {
    let jsonValue = JSON.stringify(user);
    let b64 = Base64.encode(jsonValue);
    localStorage.removeItem(this._sessionName);
    localStorage.setItem(this._sessionName, b64);
  }

  isFreshTokenValid() {
    let user = this.currentUser();
    console.log("isFreshTokenValid", user);
    return (
      user._username &&
      user._refreshToken &&
      user._refreshToken.length == 22 &&
      user._signInDateTime + user._expiredMinutesForRefreshToken * 60000 >
      new Date().getTime()
    );
  }

  isValid() {
    let user = this.currentUser();
    return (
      user._appId &&
      user._userId &&
      user._username &&
      user._accessToken &&
      user._accessToken.length == 22 &&
      user._refreshToken &&
      user._refreshToken.length == 22 &&
      user._signInDateTime + user._expiredMinutes * 60000 > new Date().getTime()
    );
  }

  setProfile(key, val) {
    let user = this.currentUser();
    user._profile[key] = val;
    this.saveSession(user);
  }

  getProfile(key, defaultVal) {
    let user = this.currentUser();
    let val = user._profile[key];
    val = val || defaultVal;
    return val;
  }

  async getNotifications() {
    if (!this.isValid()) return [];
    let user = this.currentUser();
    let url = null;
    if (user._userRole === 1) {
      url = `notification/getNotificationsFor/${user._username}`;
    } else {
      url = `notification/getNotificationsFor/${user._userId}`;
    }
    let response = await axios.get(
      url,
      {
        baseURL: AppSetting.getAPIRoot(),
        timeout: AppSetting.getConnectionTimeout(),
        headers: {
          Authorization: `Bearer ${this.getAccessToken()}`,
        }
      }
    );
    console.log("getNotifications", response);
    let res = response.data;
    if (res.result && res.result.length > 0 && res.code === "200") {
      let list = res.result;
      for (let i = 0; i < list.length; i++) {
        try {
          let not = list[i];
          not["eventComponent"] = null;
          if (not.eventDefine) {
            let eventDefine = JSON.parse(not.eventDefine);
            if (eventDefine && eventDefine.EventName && eventDefine.EventName.length > 0) {
              let btn = h(ElButton, {
                plain: true,
                size: "small",
                onClick: () => AppSetting.vueInstance.config.globalProperties.$emitter.emit(eventDefine.EventName, eventDefine.Args),
              }, eventDefine.Title);
              let eventComponent = h("div", {
                class: "event-define-panel",
              }, [btn])
              not["eventComponent"] = eventComponent;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      return res.result;
    }

    return [];
  }

  _buildProfileUrl(username, accessToken) {
    let profileUrl = `${AppSetting.getAPIRoot()}/file/profile/${username}?token=${accessToken}&timestamp=${new Date().getTime()}`;
    AppSetting.vueInstance.config.globalProperties.$store.commit(
      "handleUserProfileUrlChange",
      profileUrl
    );
    return profileUrl;
  }

  buildProfileUrl() {
    return this._buildProfileUrl(this.getUsername(), this.getAccessToken());
  }

  getUserProfileUrl(username) {
    let profileUrl = `${AppSetting.getAPIRoot()}/file/profile/${username}?token=${this.getAccessToken()}`;
    return profileUrl;
  }

  getUserDefaultProfileUrl() {
    let profileUrl = `${AppSetting.getAPIRoot()}/file/profile/default?token=${this.getAccessToken()}`;
    return profileUrl;
  }
}

export default new UserSession();
