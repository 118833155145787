<template>
    <div class="forum-message-dialog-header">
        <h4 class="dialog-header-title">
            {{ this.addForumMessageDialogTitle }}
        </h4>
        <el-button v-if="showForumSubscribeButton" type="danger" @click="subscribeForum(true)" :round="!inMiniScreen">
            <el-icon v-if="inMiniScreen">
                <Bell />
            </el-icon>
            <span v-else>Subscribe</span>
        </el-button>
        <el-button v-else type="info" @click="subscribeForum(false)" :round="!inMiniScreen">
            <el-icon v-if="inMiniScreen">
                <MuteNotification />
            </el-icon>
            <span v-else>Unsubscribe</span>
        </el-button>
    </div>

    <el-form ref="addForumMessageDialogForm" :model="forumMessage" label-width="0px">
        <div v-if="isForAdmin" class="enable-bounty">
            <el-checkbox v-model="forumMessage.enableBounty">
                Enable Bounty Post
            </el-checkbox>
        </div>
        <div class="forum-bounty-panel" v-if="isForAdmin && forumMessage.enableBounty">
            <div class="date-range">
                From:
                <el-date-picker v-model="forumMessage.startAndEndDate" type="datetimerange"
                    start-placeholder="Start Date" end-placeholder="End Date" range-separator="To"
                    :default-value="[new Date(), new Date()]"
                    @change="handleDateRangeSelection($event, forumMessage)" />
            </div>
            <div class="bounty-input">
                Bounty: <el-input-number v-model="forumMessage.bounty" :min="1" :step="50" controls-position="right" />
                points
            </div>
        </div>
        <el-form-item style="margin-bottom: 0px;">
            <AtTa :members="members">
                <el-input v-model="forumMessage.message" :rows="this.inMiniScreen ? 4 : 3" type="textarea"
                    :placeholder="this.addMessageNotes"></el-input>
            </AtTa>
        </el-form-item>
        <div class="uploaded-file-preview" v-if="forumMessage.fileList && forumMessage.fileList.length > 0">
            <el-tag v-for="(fileItem) in forumMessage.fileList" :key="fileItem.fileName" closable type="info"
                @close="removeUploadedFile(forumMessage, null, fileItem)">
                <el-icon>
                    <Paperclip />
                </el-icon>
                {{ fileItem.originalFileName }}
            </el-tag>
        </div>
        <div class="forum-message-buttons-panel">
            <div class="forum-message-comments">
                <el-upload :limit="3" ref="forumMessageUploader" accept=".png, .jpeg, .jpg, .pdf"
                    :show-file-list="false" :action="this.getForumUploadFileUrl(forumMessage)"
                    :before-upload="(rawFile) => handleForumBeforeUploadFile(forumMessage, rawFile)"
                    :on-exceed="(files, uploadFiles) => handleForumUploadFileExceed(forumMessage, files, uploadFiles)"
                    :on-success="(response, uploadFile, uploadFiles) => handleForumUploadFileSuccess(forumMessage, null, response, uploadFile, uploadFiles)"
                    :headers="headers" multiple style="display:inline-block;">
                    <el-button type="warning">
                        <el-icon>
                            <Paperclip />
                        </el-icon>
                        <span v-if="!inMiniScreen">Go Share My Ideas</span>
                    </el-button>
                </el-upload>
            </div>
            <div class="forum-message-buttons">
                <el-button @click="cancelAddingForumMessage()" link>
                    <el-icon v-if="inMiniScreen">
                        <Delete />
                    </el-icon>
                    <span v-else>Cancel</span>
                </el-button>
                <el-button v-if="!forumMessage.isEditMode" type="primary" @click="saveForumMessage(true)" round>
                    <el-icon v-if="inMiniScreen">
                        <Check />
                    </el-icon>
                    <span v-else>Add my message</span>
                </el-button>
                <el-button v-else type="primary" @click="saveForumMessage(false)" round>
                    <el-icon v-if="inMiniScreen">
                        <Check />
                    </el-icon>
                    <span v-else>Update the message</span>
                </el-button>

                <el-checkbox v-model="forumMessage.anonymous" style="margin-left: 10px">
                    <span v-if="inMiniScreen">Ano?</span>
                    <span v-else>Anonymous?</span>
                </el-checkbox>
            </div>
        </div>
    </el-form>

    <div class="forum-message-container" v-for="(forumMessageItem, index) in this.forumMessageList"
        :key="forumMessageItem.messageId">
        <div class="message-item">
            <div class="message-item-container">
                <div class="author-thumbnail">
                    <a @click="showUserPostCard(forumMessageItem)"
                        :style="forumMessageItem.anonymous ? '' : 'cursor: pointer;'">
                        <el-avatar fit="contain"
                            :src="forumMessageItem.anonymous ? this.userRef.getUserDefaultProfileUrl() : this.userRef.getUserProfileUrl(forumMessageItem.studentId)" />
                    </a>
                </div>
                <div class="item-content">
                    <div class="message-header">
                        <span class="author">
                            @{{ forumMessageItem.anonymous ? this.anonymousDisplayName : forumMessageItem.studentName }}
                        </span>
                        <span class="time">
                            {{ parentRef.$formatter.timeAgo(forumMessageItem.createdTime) }}
                        </span>
                        <span v-if="forumMessageItem.isTop" class="top">
                            <el-tag type="danger" effect="dark" size="small" round>
                                top post
                            </el-tag>
                        </span>
                        <span v-if="forumMessageItem.enableBounty && (forumMessageItem.bountyStatus || 1) === 1"
                            class="bounty">
                            <el-tag type="danger" effect="dark" size="small" round>
                                bounty worth {{ forumMessageItem.bounty }} pt,
                                <vue-countdown v-if="!forumMessageItem.expiry" style="font-size:0.8rem;"
                                    @end="onCountdownEnd(forumMessageItem)" :time="getSecondsLeft(forumMessageItem)"
                                    :transform="transformSlotProps" v-slot="{ days, hours, minutes, seconds }">
                                    remaining: {{ parseInt(days) > 0 ? days + ":" : "" }}{{ parseInt(hours) > 0 ? hours +
                ":" : "" }}{{ parseInt(minutes) > 0 ? minutes + ":" : "" }}{{ seconds }}
                                </vue-countdown>
                                <span v-else style="font-size:0.8rem;">
                                    End
                                </span>
                            </el-tag>
                        </span>
                    </div>
                    <div class="message-content-wapper">
                        <div class="message-content" v-html="this.tryReplaceURL(forumMessageItem)"></div>
                    </div>
                    <div class="message-file-list-container"
                        v-if="forumMessageItem.quoteObj && forumMessageItem.quoteObj.fl && forumMessageItem.quoteObj.fl.length > 0">
                        <div class="message-file-list-header">
                            <el-icon>
                                <Paperclip />
                            </el-icon>
                            Attachments:
                        </div>
                        <div class="message-file-list">
                            <div class="message-file-item" v-for="(fileItem) in (forumMessageItem.quoteObj.fl)"
                                :key="fileItem.d">

                                <div @click="reviewFile(fileItem)" style="cursor: pointer;"
                                    title="Click to view the large one."
                                    v-if="fileItem.n.lastIndexOf('.png') >= 0 || fileItem.n.lastIndexOf('.jpg') >= 0 || fileItem.n.lastIndexOf('.jpeg') >= 0 || fileItem.n.lastIndexOf('.gif') >= 0">
                                    <div class="file-icon">
                                        <img :src="`${this.parentRef.$appSetting.getAPIRoot()}/file/forum/getForumImage?p=${fileItem.p}&s=smallest&token=${parentRef.$user.getAccessToken()}`"
                                            :alt="fileItem.n">
                                    </div>
                                    <div class="file-title">{{ fileItem.n }}</div>
                                </div>
                                <div v-else @click="reviewFile(fileItem)" style="cursor: pointer;"
                                    title="Click to view the file.">
                                    <div class="file-icon">
                                        <img src="../../assets/img/pdf-icon.jpg">
                                    </div>
                                    <div class="file-title">{{ fileItem.n }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="message-action-buttons">
                        <a @click="thumbIt(forumMessageItem, true)">👍</a>
                        <span class="count">
                            {{ forumMessageItem.thumbUp }}
                        </span>
                        <a @click="thumbIt(forumMessageItem, false)">👎</a>
                        <span class="count">
                            {{ forumMessageItem.thumbDown }}
                        </span>
                        <a v-if="forumMessageItem.showRecallButton"
                            @click="recallForumMessage(index, forumMessageItem, this.forumMessageList)">
                            Recall
                        </a>
                        <a @click="replyForumMessage(index, forumMessageItem)">
                            Reply
                        </a>
                        <a v-if="isForAdmin && forumMessageItem.enableBounty && (forumMessageItem.bountyStatus || 1) === 1"
                            class="admin-operator"
                            @click="editForumMessage(index, forumMessageItem, this.forumMessageList)">
                            Edit
                        </a>
                        <a v-if="isForAdmin && (forumMessageItem.bountyStatus || 1) === 1" class="admin-operator"
                            @click="deleteForumMessage(index, forumMessageItem, this.forumMessageList)">
                            Delete
                        </a>
                        <a v-if="isForAdmin && !forumMessageItem.isTop" class="admin-operator"
                            @click="topForumMessage(index, forumMessageItem, this.forumMessageList, true)">
                            Top
                        </a>
                        <a v-if="isForAdmin && forumMessageItem.isTop" class="admin-operator"
                            @click="topForumMessage(index, forumMessageItem, this.forumMessageList, false)">
                            Un-Top
                        </a>
                    </div>
                </div>
            </div>
            <div :id="`reply_message_container_${forumMessageItem.messageId}`"
                style="margin-left: 50px; margin-top: 16px;" v-if="forumMessageItem.replyComponent1">
                <component v-bind:is="forumMessageItem.replyComponent1"></component>
                <component v-bind:is="forumMessageItem.fileListPreviewComponent"></component>
                <component v-bind:is="forumMessageItem.replyComponent2"></component>
            </div>

            <div v-if="forumMessageItem.repliedCount > 0" class="reply-button-area">
                <el-link @click="showAndLoadForumRepliedMessage(index, forumMessageItem)">
                    <el-icon>
                        <caretTop v-if="forumMessageItem.showShowingRepliedForumMessageContainer" />
                        <caretBottom v-else />
                    </el-icon>
                    {{ forumMessageItem.repliedCount }} replies
                </el-link>
            </div>
            <div style="padding-left:48px;" v-if="forumMessageItem.showShowingRepliedForumMessageContainer">
                <div class="forum-message-container"
                    v-for="(repliedMessageItem, repliedMessageItemIndex) in (forumMessageItem.repliedMessageList || [])"
                    :key="repliedMessageItem.messageId">
                    <div class="message-item">
                        <div class="message-item-container">
                            <div class="author-thumbnail">
                                <a @click="showUserPostCard(repliedMessageItem)"
                                    :style="repliedMessageItem.anonymous ? '' : 'cursor: pointer;'">
                                    <el-avatar fit="contain"
                                        :src="repliedMessageItem.anonymous ? this.userRef.getUserDefaultProfileUrl() : this.userRef.getUserProfileUrl(repliedMessageItem.studentId)" />
                                </a>
                            </div>
                            <div class="item-content">
                                <div class="message-header">
                                    <span class="author">
                                        @{{ repliedMessageItem.anonymous ? this.anonymousDisplayName :
                repliedMessageItem.studentName }}
                                    </span>
                                    <span class="time">
                                        {{ parentRef.$formatter.timeAgo(repliedMessageItem.createdTime) }}
                                    </span>
                                    <span v-if="forumMessageItem.enableBounty && repliedMessageItem.isBestAnswer"
                                        class="top">
                                        <el-tag type="danger" effect="dark" size="small" round>
                                            Best Answer
                                        </el-tag>
                                    </span>
                                </div>
                                <div class="message-content-wapper">
                                    <div class="message-content">
                                        <span style="color:#065fd4;"
                                            v-if="repliedMessageItem.quoteObj && repliedMessageItem.quoteObj.sn"
                                            class="auote-author">@{{
                this.getAtSomeoneName(forumMessageItem.repliedMessageList,
                    repliedMessageItem) }}&nbsp;</span>
                                        <span v-html="this.tryReplaceURL(repliedMessageItem)"></span>
                                    </div>
                                </div>

                                <div class="message-file-list-container"
                                    v-if="repliedMessageItem.quoteObj && repliedMessageItem.quoteObj.fl && repliedMessageItem.quoteObj.fl.length > 0">
                                    <div class="message-file-list-header">
                                        <el-icon>
                                            <Paperclip />
                                        </el-icon>
                                        Attachments:
                                    </div>
                                    <div class="message-file-list">
                                        <div class="message-file-item"
                                            v-for="(fileItem) in (repliedMessageItem.quoteObj.fl)" :key="fileItem.d">

                                            <div @click="reviewFile(fileItem)" style="cursor: pointer;"
                                                title="Click to view the large one."
                                                v-if="fileItem.n.lastIndexOf('.png') >= 0 || fileItem.n.lastIndexOf('.jpg') >= 0 || fileItem.n.lastIndexOf('.jpeg') >= 0 || fileItem.n.lastIndexOf('.gif') >= 0">
                                                <div class="file-icon">
                                                    <img :src="`${this.parentRef.$appSetting.getAPIRoot()}/file/forum/getForumImage?p=${fileItem.p}&s=smallest&token=${parentRef.$user.getAccessToken()}`"
                                                        :alt="fileItem.n">
                                                </div>
                                                <div class="file-title">{{ fileItem.n }}</div>
                                            </div>
                                            <div v-else @click="reviewFile(fileItem)" style="cursor: pointer;"
                                                title="Click to view the file.">
                                                <div class="file-icon">
                                                    <img src="../../assets/img/pdf-icon.jpg">
                                                </div>
                                                <div class="file-title">{{ fileItem.n }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="message-action-buttons">
                                    <a @click="thumbIt(repliedMessageItem, true)">👍</a>
                                    <span class="count">
                                        {{ repliedMessageItem.thumbUp }}
                                    </span>
                                    <a @click="thumbIt(repliedMessageItem, false)">👎</a>
                                    <span class="count">
                                        {{ repliedMessageItem.thumbDown }}
                                    </span>
                                    <a v-if="repliedMessageItem.showRecallButton"
                                        @click="recallForumMessage(repliedMessageItemIndex, repliedMessageItem, forumMessageItem.repliedMessageList)">
                                        Recall
                                    </a>
                                    <a @click="replyForumMessage(repliedMessageItemIndex, repliedMessageItem)">
                                        Reply
                                    </a>
                                    <a v-if="isForAdmin && forumMessageItem.enableBounty && (forumMessageItem.bountyStatus || 1) === 1"
                                        class="admin-operator"
                                        @click="chooseForumMessageAsBestAns(repliedMessageItemIndex, forumMessageItem, repliedMessageItem)">
                                        Choose as best ans.
                                    </a>
                                    <a v-if="isForAdmin && !repliedMessageItem.isBestAnswer" class="admin-operator"
                                        @click="deleteForumMessage(repliedMessageItemIndex, repliedMessageItem, forumMessageItem.repliedMessageList)">
                                        Delete
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div :id="`reply_message_container_${repliedMessageItem.messageId}`"
                            style="margin-left: 50px; margin-top: 16px;" v-if="repliedMessageItem.replyComponent1">
                            <component v-bind:is="repliedMessageItem.replyComponent1"></component>
                            <component v-bind:is="repliedMessageItem.fileListPreviewComponent"></component>
                            <component v-bind:is="repliedMessageItem.replyComponent2"></component>
                        </div>
                    </div>
                </div>
                <a v-if="forumMessageItem.showShowingMoreRepliedForumMessageButton" class="show-more-message"
                    @click="showMoreRepliedForumMessage(forumMessageItem)">
                    Show more replies
                </a>
                <div v-else class="no-more-message">-- END --</div>
            </div>
        </div>
    </div>
    <a v-if="showShowingMoreForumMessageButton" class="show-more-message" @click="showMoreForumMessage">
        Show more messages
    </a>
    <div v-else class="no-more-message">-- END --</div>
</template>

<script>
import { getData, postData, putData, deleteData, downloadFile } from "../../service/api";
import { h } from "vue";
import { ElCheckbox, ElButton, ElDatePicker, ElInputNumber, ElInput, ElForm, ElFormItem, ElIcon, ElUpload, ElAvatar, ElLink, ElMessageBox, ElMessage, ElTag } from "element-plus";
import AtTa from "../../components/common/AtTextarea.vue";
import UserPostcard from "./UserPostcard.vue";
import { CaretBottom, CaretTop, UploadFilled, Paperclip, MuteNotification, Bell, Check, Delete } from "@element-plus/icons";
import objectExtensions from "../../service/utils/objectExtensions";
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    components: {
        UserPostcard, VueCountdown,
        AtTa, ElCheckbox, ElButton, ElDatePicker, ElInputNumber, ElInput, ElForm, ElFormItem, ElIcon, ElUpload, ElAvatar, ElLink, ElMessageBox, ElMessage, ElTag,
        CaretBottom, CaretTop, UploadFilled, Paperclip, MuteNotification, Bell, Check, Delete,
    },
    props: {
        forumId: String,
        isForAdmin: Boolean,
        userRef: Object,
        parentRef: Object,
    },
    data() {
        return {
            controllerUrl: "/forum",
            headers: { authorization: `Bearer ${this.parentRef.$user.getAccessToken()}` },
            addForumMessageDialogTitle: "",
            formData: {},
            forumMessage: {},
            addForumMessageDialogVisible: false,
            forumMessageList: [{ anonymous: false, repliedMessageList: [], fileList: [] }],
            forumMessageQuery: {
                forumId: null,
                deep: 1,
                status: "Normal",
                enableTopFirst: true,
                orderBy: "LastRepliedTime",
                orderDirection: "DESC",
                pageIndex: 1,
                pageSize: 5,
                itemTotal: 0,
                pageTotal: 0,
            },
            repliedMessageQuery: {
                rootMessageId: null,
                deep: 2,
                status: "Normal",
                orderBy: "CreatedTime",
                orderDirection: "DESC",
                pageIndex: 1,
                pageSize: 5,
                itemTotal: 0,
                pageTotal: 0,
            },
            atSomeone: [
                {
                    name: "toClassroom",
                    title: "All Classmates",
                }, {
                    name: "toGrade",
                    title: "All Grades",
                }, {
                    name: "toTeacher",
                    title: "Teacher",
                }
            ],
            members: [],
            repliedMessage: "",
            showShowingMoreForumMessageButton: false,
            showForumSubscribeButton: true,
            unreadMessageCountList: [],
            anonymousDisplayName: "Anonym",
            addMessageNotes: "Say something, and try @All Classmates, @All Grades, @Teacher, and contribute/share your incredible ideas by uploading your imagination, pdf/word/jpg/png files with a size less than 2MB are acceptable.",
        }
    },
    created() {
        console.log("created", objectExtensions);

        for (let i = 0; i < this.atSomeone.length; i++) {
            this.members.push(this.atSomeone[i].title);
        }

        console.log("created", this.members, this.forumId, this.isForAdmin, this, this.userRef);

        this.$nextTick(async function () {
            this.loadForumData();
        });
    },
    computed: {
        inMiniScreen() {
            return this.parentRef.$store.state.inMiniScreen;
        },
    },
    methods: {
        loadForumData() {
            getData(`${this.controllerUrl}/${this.forumId}`).then((res) => {
                if (res.result && res.code === "200") {
                    this.forumData = res.result;

                    this.addForumMessageDialogTitle = this.forumData.name;

                    this.showShowingMoreForumMessageButton = true;
                    this.forumMessageQuery.forumId = this.forumData.forumId;
                    this.forumMessageQuery.pageIndex = 1;
                    this.forumMessageQuery.itemTotal = 0;
                    this.forumMessageQuery.pageTotal = 1;
                    this.loadForumMessageData();
                }
            });
        },
        async showLessonForum(evt, leafData) {
            console.log("unlockLessonItem", evt, leafData);
            this.forumMessage = {};
            this.showForumSubscribeButton = await this.hasUserSubscribedForum(
                leafData.lessonId
            );
            this.addForumMessageDialogTitle = leafData.name;

            this.unreadForumMessageCount = 0;
            let countNodeRef = document.getElementById(`unread_forum_message_count_${leafData.lessonId}`);
            if (countNodeRef) {
                countNodeRef.innerHTML = "0";
                countNodeRef.style.display = this.unreadForumMessageCount > 0 ? "flex" : "none";
            }

            this.clearStudentUnreadForumMessageCount(leafData.lessonId);
            // redraw leaf to remove dot if unread count large than 0
            let itemData = this.areaData.find(
                (e) => e.type === "LEAF" && e.data.lessonId === leafData.lessonId
            );
            if (itemData) this.reDrawLeaf(itemData, false, false);
            this.refreshReadCount(this.forumMessageQuery.forumId);
        },
        refreshReadCount(forumId) {
            // refresh read count
            putData(
                "/forum",
                `${forumId}/refreshReadCount`,
                {},
                {
                    isShowLoading: false,
                }
            );
        },
        showMoreForumMessage() {
            this.forumMessageQuery.pageIndex = this.forumMessageQuery.pageIndex + 1;
            this.loadForumMessageData();
        },
        showMoreRepliedForumMessage(forumMessageItem) {
            let query = forumMessageItem.repliedMessageQuery;
            query.pageIndex = query.pageIndex + 1;
            this.loadRepliedForumMessageData(forumMessageItem);
        },
        loadForumMessageData() {
            // load forum message list
            getData("/forum/message", this.forumMessageQuery).then((res) => {
                if (res.result && res.code === "200") {
                    for (let i = 0; i < res.result.list.length; i++) {
                        this.initialAdditionalFieldsForForumMessage(res.result.list[i]);
                    }
                    let arrayRef = this.forumMessageList;
                    if (this.forumMessageQuery.pageIndex > 1) {
                        this.forumMessageList = this.forumMessageList.concat(res.result.list);
                    } else {
                        this.forumMessageList = res.result.list;
                    }
                    arrayRef.splice(0, arrayRef.length); //empty previous array
                    this.checkShowingRecallButton(this.forumMessageList);
                    console.log(
                        "loadForumMessageData",
                        this.forumMessageQuery,
                        res.result.list,
                        this.forumMessageList
                    );
                    this.forumMessageQuery.pageIndex = res.result.pageIndex;
                    this.forumMessageQuery.pageSize = res.result.pageSize;
                    this.forumMessageQuery.itemTotal = res.result.count;
                    this.forumMessageQuery.pageTotal = Math.ceil(
                        res.result.count / res.result.pageSize
                    );

                    if (this.forumMessageQuery.pageIndex >= this.forumMessageQuery.pageTotal) {
                        this.showShowingMoreForumMessageButton = false;
                    }

                    this.$nextTick(function () {
                        setTimeout(function (_this) {
                            _this.showAndHideMoreContent();
                        }, 100, this);
                    });
                }
                this.addForumMessageDialogVisible = true;
            });
        },
        loadRepliedForumMessageData(forumMessageItem) {
            // load replied forum message list
            if (!forumMessageItem) return;
            let query = forumMessageItem.repliedMessageQuery;
            query.rootMessageId = forumMessageItem.messageId;
            query.deep = 2;
            getData("/forum/message", query).then((res) => {
                if (res.result && res.code === "200") {
                    for (let i = 0; i < res.result.list.length; i++) {
                        this.initialAdditionalFieldsForForumMessage(res.result.list[i]);
                    }
                    let arrayRef = forumMessageItem.repliedMessageList;
                    if (query.pageIndex > 1) {
                        forumMessageItem.repliedMessageList = forumMessageItem.repliedMessageList.concat(res.result.list);
                    } else {
                        forumMessageItem.repliedMessageList = res.result.list;
                    }
                    arrayRef.splice(0, arrayRef.length); //empty previous array
                    this.checkShowingRecallButton(res.result.list);
                    console.log(
                        "loadForumMessageData",
                        query,
                        res.result.list,
                        forumMessageItem.repliedMessageList
                    );
                    query.pageIndex = res.result.pageIndex;
                    query.pageSize = res.result.pageSize;
                    query.itemTotal = res.result.count;
                    query.pageTotal = Math.ceil(
                        res.result.count / res.result.pageSize
                    );

                    if (query.pageIndex >= query.pageTotal) {
                        forumMessageItem.showShowingMoreRepliedForumMessageButton = false;
                    }

                    this.$nextTick(function () {
                        setTimeout(function (_this) {
                            _this.showAndHideMoreContent();
                        }, 100, this);
                    });
                }
            });
        },
        initialAdditionalFieldsForForumMessage(forumMessageItem) {
            forumMessageItem["expiry"] = false;
            forumMessageItem["isEditMode"] = false;
            forumMessageItem["showRecallButton"] = false;
            forumMessageItem["replyComponent"] = null;
            forumMessageItem["fileList"] = [];
            if (forumMessageItem.deep === 1) {
                forumMessageItem["repliedMessageList"] = [];
                forumMessageItem["repliedMessageQuery"] = JSON.parse(JSON.stringify(this.repliedMessageQuery));
                forumMessageItem["showShowingRepliedForumMessageContainer"] = false;
                forumMessageItem["showShowingMoreRepliedForumMessageButton"] = true;
            }
            if (forumMessageItem.quote) {
                forumMessageItem["quoteObj"] = JSON.parse(forumMessageItem.quote);
            }
        },
        checkShowingRecallButton(forumMessageList) {
            for (let i = 0; i < forumMessageList.length; i++) {
                let forumMessageItem = forumMessageList[i];
                let createdTime = new Date(forumMessageItem.createdTime);
                let checked = false;
                if (this.userRef.getUserRole() === 1 || this.userRef.getUserRole() === "Student") {
                    checked = String.equal(forumMessageItem.studentId, this.userRef.getUsername(), true) && Date.now() < createdTime.getTime() + 60000;
                } else {
                    checked = String.equal(forumMessageItem.studentName, this.userRef.getUsername(), true) && Date.now() < createdTime.getTime() + 60000;
                }
                forumMessageItem.showRecallButton = checked;
            }
            if (forumMessageList && forumMessageList.length > 0) {
                setTimeout(
                    (list, _this) => {
                        _this.checkShowingRecallButton(list);
                    },
                    1000,
                    forumMessageList, this
                );
            }

        },
        cancelAddingForumMessage() {
            this.deleteAllUploadFiles(this.forumMessage);
            this.$refs.forumMessageUploader.clearFiles();
            this.forumMessage = { anonymous: false, fileList: [] };
        },
        deleteAllUploadFiles(forumMessage) {
            if (forumMessage.fileList && forumMessage.fileList.length > 0) {
                postData(`/file/forum/${this.forumId}/deleteFiles`, forumMessage.fileList,
                    {
                        isShowLoading: false,
                    }).then((res) => {
                        console.log("deleteAllUploadFiles", res);
                        forumMessage.fileList = forumMessage.fileList.slice(0, forumMessage.fileList.length - 1);
                    });
            }
        },
        async saveForumMessage(isCreating) {
            console.log("saveForumMessage");
            if (!this.forumMessage.message) {
                ElMessage.error("You haven't said something.");
                return;
            }

            let quoteObj = null;
            let quoteJson = null;
            let fileListSummary = [];
            if (this.forumMessage.fileList && this.forumMessage.fileList.length) {
                for (let i = 0; i < this.forumMessage.fileList.length; i++) {
                    let fileObj = this.forumMessage.fileList[i];
                    fileListSummary.push({ d: fileObj.fileId, p: `${fileObj.relativePath}${fileObj.fileName}`, n: fileObj.originalFileName });
                }
                if (!quoteObj) quoteObj = {};
                quoteObj.fl = fileListSummary;
            }

            if (quoteObj) quoteJson = JSON.stringify(quoteObj);

            let forumMessageEntity = {
                messageId: isCreating ? null : this.forumMessage.messageId,
                forumId: this.forumMessageQuery.forumId,
                rootMessageId: null,
                parentMessageId: null,
                anonymous: this.forumMessage.anonymous,
                message: this.forumMessage.message,
                quote: quoteJson,
                enableBounty: this.forumMessage.enableBounty,
                startOfferTime: this.forumMessage.startOfferTime,
                endOfferTime: this.forumMessage.endOfferTime,
                bounty: this.forumMessage.bounty,
                fileList: this.forumMessage.fileList,
            };
            let res = await this.submitForumMessage(forumMessageEntity, isCreating);
            if (res) {
                this.forumMessageList.splice(0, 0, res);
                this.forumMessage.fileList && this.forumMessage.fileList.slice(0, this.forumMessage.fileList.length - 1);
                this.forumMessage = { anonymous: false, fileList: [] };
            }
        },
        async submitForumMessage(forumMessageEntity, isCreating) {
            let forumMessageData = {
                messageId: forumMessageEntity.messageId,
                forumId: forumMessageEntity.forumId,
                deep: forumMessageEntity.parentMessageId ? 2 : 1,
                rootMessageId: forumMessageEntity.rootMessageId,
                parentMessageId: forumMessageEntity.parentMessageId,
                anonymous: forumMessageEntity.anonymous,
                message: forumMessageEntity.message,
                quote: forumMessageEntity.quote,
                enableBounty: forumMessageEntity.enableBounty,
                startOfferTime: forumMessageEntity.startOfferTime,
                endOfferTime: forumMessageEntity.endOfferTime,
                bounty: forumMessageEntity.bounty,
                fileList: forumMessageEntity.fileList,
                status: 2048,
            };
            console.log("submitForumMessage", forumMessageData, isCreating);
            let res = null;
            if (isCreating) {
                res = await postData(`/forum/${forumMessageData.forumId}/addMessage`, forumMessageData);
            }
            else {
                res = await putData(`/forum/updateMessage/`, forumMessageData.messageId, forumMessageData);
            }
            if (res.result && res.code === "200") {
                this.initialAdditionalFieldsForForumMessage(res.result);
                this.refreshReadCount(forumMessageData.forumId);
                res.result["showRecallButton"] = true;
                this.$nextTick(function () {
                    setTimeout(function (_this) {
                        _this.showAndHideMoreContent();
                    }, 100, this);
                });
            }

            return res.result;
        },
        recallForumMessage(index, forumMessageItem, list) {
            putData(
                "/forum/message",
                `${forumMessageItem.messageId}/recall`,
                {},
                {
                    isShowLoading: false,
                }
            ).then((res) => {
                if (res.result && res.code === "200") {
                    list.splice(index, 1);
                    if (forumMessageItem.deep === 2) {
                        let item = this.forumMessageList.find(e => e.messageId === forumMessageItem.rootMessageId);
                        if (item) {
                            item.repliedCount = item.repliedCount - 1;
                        }
                    }
                }
            });
        },
        thumbIt(item, up) {
            putData(
                "/forum/message",
                `${item.messageId}/thumbIt`,
                {
                    thumbUp: up,
                },
                {
                    isShowLoading: false,
                }
            ).then((res) => {
                if (res.result && res.code === "200") {
                    item.thumbUp = res.result.thumbUp;
                    item.thumbDown = res.result.thumbDown;
                }
            });
        },
        async hasUserSubscribedForum(forumId) {
            let subscribedIdList = await this.getSubscribedIdList();
            if (subscribedIdList && subscribedIdList.indexOf(forumId) >= 0) {
                return false;
            }
            return true;
        },
        subscribeForum(actionType) {
            let forumId = this.forumMessageQuery.forumId;
            let studentId = this.userRef.getUsername();
            putData(
                "/student",
                `${studentId}/${actionType ? "subscribe" : "unsubscribe"}`,
                {
                    objectType: "Forum",
                    objectKey: forumId,
                    description: this.formData.name,
                },
                {
                    isShowLoading: false,
                }
            ).then((res) => {
                if (res.result && res.code === "200") {
                    if (actionType) {
                        this.addSubscribedIdIntoList(forumId);
                        this.showForumSubscribeButton = false;
                    } else {
                        this.removeSubscribedIdFromList(forumId);
                        this.showForumSubscribeButton = true;
                    }
                }
            });
        },
        async getSubscribedIdList() {
            let key = `student_subscription_id_list_${this.userRef.getUsername()}`;
            let value = this.userRef.getProfile(key, "");
            let subscribedIdList = [];
            if (value.length <= 0) {
                // load user's subscription list
                let subscriptionQuery = {
                    studentId: this.userRef.getUsername(),
                    pageIndex: 1,
                    pageSize: -1,
                };

                let res = await getData("/subscription", subscriptionQuery, {
                    isShowLoading: false,
                });
                if (res.result && res.code === "200") {
                    for (let i = 0; i < res.result.list.length; i++) {
                        subscribedIdList.push(res.result.list[i].objectKey);
                    }
                    this.userRef.setProfile(key, JSON.stringify(subscribedIdList));
                    console.log("getSubscribedIdList", subscribedIdList);
                }
            } else {
                subscribedIdList = JSON.parse(value);
            }
            return subscribedIdList;
        },
        async addSubscribedIdIntoList(id) {
            let key = `student_subscription_id_list_${this.userRef.getUsername()}`;
            let subscribedIdList = await this.getSubscribedIdList();
            if (subscribedIdList.indexOf(id) < 0) {
                subscribedIdList.push(id);
                this.userRef.setProfile(key, JSON.stringify(subscribedIdList));
            }
        },
        async removeSubscribedIdFromList(id) {
            let key = `student_subscription_id_list_${this.userRef.getUsername()}`;
            let subscribedIdList = await this.getSubscribedIdList();
            let pos = subscribedIdList.indexOf(id);
            if (pos >= 0) {
                subscribedIdList.splice(pos, 1);
                this.userRef.setProfile(key, JSON.stringify(subscribedIdList));
            }
        },
        getStudentUnreadForumMessageCount(forumId) {
            if (this.unreadMessageCountList && this.unreadMessageCountList.length) {
                let item = this.unreadMessageCountList.find(e => e.forumId === forumId);
                return item ? item.unreadCount : 0;
            }
            return 0;
        },
        clearStudentUnreadForumMessageCount(forumId) {
            if (this.unreadMessageCountList && this.unreadMessageCountList.length) {
                let item = this.unreadMessageCountList.find(e => e.forumId === forumId);
                if (item) item.unreadCount = 0;
            }
        },
        replyForumMessage(index, replyToForumMessage) {
            let forumMessage = {
                forumId: replyToForumMessage.forumId,
                parentMessageId: replyToForumMessage.messageId,
                rootMessageId: (replyToForumMessage.deep === 1 && !replyToForumMessage.rootMessageId) ? replyToForumMessage.messageId : replyToForumMessage.rootMessageId,
                enableBounty: false,
                anonymous: false,
                message: "",
                fileList: []
            };
            if (replyToForumMessage.replyComponent1) {
                this.showOrHideReplyForumMessageConatiner(index, forumMessage, replyToForumMessage, true);
                return;
            }
            console.log("replyForumMessage", index, replyToForumMessage);

            let textareaElement = h(
                "textarea",
                {
                    rows: this.inMiniScreen ? 4 : 3,
                    placeholder: replyToForumMessage.deep === 2 ? `@${replyToForumMessage.anonymous ? this.anonymousDisplayName : replyToForumMessage.studentName}` : this.addMessageNotes,
                    class: "el-textarea__inner",
                    onInput: (e) => {
                        let _e = e;
                        setTimeout(function () {
                            forumMessage.message = _e.target.value;
                        }, 10)
                    },
                }
            );
            let atMembers = JSON.parse(JSON.stringify(this.members));
            // atMembers.push(replyToForumMessage.studentName);
            let textAreaContainer = h(
                AtTa,
                {
                    members: atMembers,
                    type: "textarea",
                },
                [textareaElement]
            );

            let uploadBtn = h(ElButton, { type: "warning" }, [h(ElIcon, [h(Paperclip)])], this.inMiniScreen ? null : "Go Share My Ideas");
            let uploadElm = h(ElUpload, {
                accept: ".png, .jpeg, .jpg, .pdf",
                action: this.getForumUploadFileUrl(forumMessage),
                limit: 3,
                headers: this.headers,
                multiple: true,
                showFileList: false,
                style: "display:inline-block;",
                onExceed: (files, uploadFiles) => this.handleForumUploadFileExceed(forumMessage, replyToForumMessage, files, uploadFiles),
                onSuccess: (response, uploadFile, uploadFiles) => this.handleForumUploadFileSuccess(forumMessage, replyToForumMessage, response, uploadFile, uploadFiles),
            }, [uploadBtn]);
            let uploadContainer = h("div", {
                class: "forum-message-comments",
            }, [uploadElm]);

            let anonymousCheckBox = h(ElCheckbox, {
                style: "margin-left:10px;",
                onChange: ($event) => {
                    forumMessage.anonymous = $event;
                },
            },
                this.inMiniScreen ? "Ano?" : "Anonymous?");
            let cancelButton = h(ElButton, {
                link: true,
                onClick: () => this.cancelAddingForumReplyMessage(index, forumMessage, replyToForumMessage, textareaElement, anonymousCheckBox, uploadElm),
            },
                this.inMiniScreen ? h(ElIcon, [h(Delete)]) : "Cancel");
            let okButton = h(ElButton, {
                type: "primary",
                round: true,
                onClick: () => this.saveForumReplyMessage(index, forumMessage, replyToForumMessage),
            },
                this.inMiniScreen ? h(ElIcon, [h(Check)]) : "Add my message");

            let buttonContainerElement = h("div", {
                class: "forum-message-buttons",
            },
                [
                    cancelButton,
                    okButton,
                    anonymousCheckBox,
                ]);


            let buttonPanelElement = h("div", {
                class: "forum-message-buttons-panel"
            },
                [
                    uploadContainer,
                    buttonContainerElement,
                ]);

            replyToForumMessage.replyComponent1 = textAreaContainer;
            replyToForumMessage.replyComponent2 = buttonPanelElement;
        },
        buildFileListPreviewComponent(forumMessage, replyToForumMessage) {
            replyToForumMessage.fileListPreviewComponent = null;
            this.$nextTick(function () {
                let filePreviewPanel = h(
                    "div",
                    {
                        class: "uploaded-file-preview"
                    },
                    forumMessage.fileList.map(fileItem => {
                        return h(ElTag, {
                            closable: true,
                            type: "info",
                            onClose: () => this.removeUploadedFile(forumMessage, replyToForumMessage, fileItem)
                        }, fileItem.originalFileName)
                    })
                )
                if (replyToForumMessage) {
                    replyToForumMessage.fileListPreviewComponent = filePreviewPanel;
                    console.log("replyToForumMessage.fileListPreviewComponent", replyToForumMessage.fileListPreviewComponent);
                }
            });

        },
        cancelAddingForumReplyMessage(index, forumMessage, replyToForumMessage) {
            this.deleteAllUploadFiles(forumMessage);
            replyToForumMessage.replyComponent1 = null;
            replyToForumMessage.fileListPreviewComponent = null;
            replyToForumMessage.replyComponent2 = null;
        },
        showAndLoadForumRepliedMessage(index, forumMessageItem) {
            let show = forumMessageItem.showShowingRepliedForumMessageContainer;
            if (show) {
                forumMessageItem.showShowingRepliedForumMessageContainer = false;
            } else {
                forumMessageItem.showShowingRepliedForumMessageContainer = true;
                console.log("showAndLoadForumRepliedMessage", forumMessageItem, forumMessageItem.repliedCount, forumMessageItem.repliedMessageList);
                if (forumMessageItem.repliedCount > 0 && forumMessageItem.repliedMessageList.length === 0) {
                    this.loadRepliedForumMessageData(forumMessageItem);
                }
            }
        },
        showOrHideReplyForumMessageConatiner(index, forumMessage, replyToForumMessage, show) {
            let conatainer = document.getElementById(`reply_message_container_${replyToForumMessage.messageId}`);
            forumMessage.messageId = "";
            forumMessage.message = "";
            forumMessage.anonymous = false;
            forumMessage.fileList = [];
            conatainer.style.display = show ? "block" : "none";
        },
        async saveForumReplyMessage(index, forumMessage, parentForumMessage) {
            let quoteObj = null;
            let fileListSummary = [];
            if (!forumMessage.message) {
                ElMessage.error("You haven't said something.");
                return;
            }

            if (forumMessage.fileList && forumMessage.fileList.length) {
                for (let i = 0; i < forumMessage.fileList.length; i++) {
                    let fileObj = forumMessage.fileList[i];
                    fileListSummary.push({ d: fileObj.fileId, p: `${fileObj.relativePath}${fileObj.fileName}`, n: fileObj.originalFileName });
                }
                if (!quoteObj) quoteObj = {};
                quoteObj.fl = fileListSummary;
            }

            if (parentForumMessage.deep > 1) {
                if (!quoteObj) quoteObj = {};
                quoteObj.si = parentForumMessage.studentId;
                quoteObj.sn = parentForumMessage.studentName;
            }

            if (quoteObj)
                forumMessage.quote = JSON.stringify(quoteObj);

            let res = await this.submitForumMessage(forumMessage, true);
            if (res) {
                let item = this.forumMessageList.find(e => e.messageId === forumMessage.rootMessageId);
                if (item) {
                    item.repliedCount = (item.repliedCount || 0) + 1;
                    item.repliedMessageList.splice(0, 0, res);
                }

                forumMessage.anonymous = false;
                forumMessage.message = "";
                forumMessage.fileList = [];
                this.showOrHideReplyForumMessageConatiner(index, forumMessage, parentForumMessage, false);
                parentForumMessage.replyComponent1 = null;
                parentForumMessage.fileListPreviewComponent = null;
                parentForumMessage.replyComponent2 = null;
            }
        },
        messageConextLoaded(evt, replyToForumMessage) {
            console.log("messageConextLoaded", evt, replyToForumMessage);
        },
        showAndHideMoreContent() {
            const showLines = 3;
            const items = document.getElementsByClassName("message-content");
            const attributeName = "calculated";
            for (let i = 0; i < items.length; i++) {
                let el = items[i];
                if (!el.hasAttribute(attributeName)) {
                    let style = this.getElementStyleInfo(el);
                    let lineHeight = style.lineHeight;
                    let lineCount = style.lineCount;
                    el.setAttribute(attributeName, "1")

                    if (lineCount > showLines) {
                        el.style.height = `${lineHeight * showLines}px`;
                        el.style.textOverflow = "ellipsis";
                        el.style.overflow = "hidden";
                        let moreButton = document.createElement("span");
                        moreButton.innerHTML = "Show more";
                        moreButton.style.cursor = "pointer";
                        moreButton.style.fontWeight = 500;
                        moreButton.style.color = "#65b1f4";
                        moreButton.setAttribute("h", "1");
                        moreButton.setAttribute("contentLineHeight", lineHeight);
                        moreButton.addEventListener('click', function (evt) {
                            let tar = evt.target;
                            console.log("moreButton.addEventListener", tar.previousElementSibling)
                            let contentEl = tar.previousElementSibling;
                            let mode = tar.getAttribute("h") === "1";
                            if (contentEl) {
                                contentEl.style.height = mode ? "auto" : `${parseFloat(moreButton.getAttribute("contentLineHeight")) * showLines
                                    }px`;
                                contentEl.style.textOverflow = mode ? "string" : "ellipsis";
                                contentEl.style.overflow = mode ? "visible" : "hidden";
                                tar.innerHTML = mode ? "Show less" : "Show more";
                                tar.setAttribute("h", mode ? "0" : "1")
                            }
                        }, false);
                        el.parentNode.appendChild(moreButton);
                    }
                }
            }
        },
        getElementStyleInfo(el) {
            let style = el.currentStyle || document.defaultView.getComputedStyle(el, null);
            let contentHeight = el.offsetHeight || el.getBoundingClientRect().height || getComputedStyle(el).height
            let font_size = style.getPropertyValue("font-size");
            let line_height = style.getPropertyValue("line-height");
            let lineHeight = Math.round(line_height ? parseFloat(line_height) : parseFloat(font_size) * 1.3);
            let lineCount = Math.ceil(contentHeight / lineHeight);
            return { "lineHeight": lineHeight, "height:": contentHeight, "lineCount": lineCount };
        },
        handleEnableBountyChange(checked, forumMessage, replyToForumMessage) {
            console.log("handleEnableBountyChange", checked, forumMessage);
            forumMessage.enableBounty = checked;
            if (replyToForumMessage) {
                let bountyPanel = document.getElementById(`reply_message_bounty_panel_${replyToForumMessage.messageId}`);
                bountyPanel.style.display = checked ? "flex" : "none";
            }
        },
        handleDateRangeSelection(dateRange, forumMessage) {
            console.log("handleDateRangeSelection", dateRange, forumMessage.startAndEndDate);
            forumMessage.startOfferTime = dateRange[0].toUTCString();
            forumMessage.endOfferTime = dateRange[1].toUTCString();
        },
        getForumUploadFileUrl(forumMessage) {
            if (forumMessage.isEditMode) {
                return `${this.parentRef.$appSetting.getAPIRoot()}/file/forum/${this.forumId}/addFileForMessage/${forumMessage.messageId}`;
            }
            return `${this.parentRef.$appSetting.getAPIRoot()}/file/forum/${this.forumId}/addFile`;
        },
        removeUploadedFile(forumMessage, replyToForumMessage, uploadFile) {
            return ElMessageBox.confirm(`Cancel the transfer of ${uploadFile.originalFileName} ?`, "Prompt", { type: "warning" }).then(
                () => {
                    let uploadFiles = this.$refs.forumMessageUploader.uploadFiles;
                    let pos = forumMessage.fileList.findIndex(e => e.fileName == uploadFile.fileName);
                    if (pos >= 0) {
                        let fileObj = forumMessage.fileList[pos];
                        postData(`/file/forum/${this.forumId}/deleteFiles`, [fileObj]).then(() => {
                            forumMessage.fileList.splice(pos, 1)
                            pos = uploadFiles.findIndex(e => e.uid === uploadFile.uid);
                            if (pos >= 0) uploadFiles.splice(pos, 1)
                            if (replyToForumMessage) this.buildFileListPreviewComponent(forumMessage, replyToForumMessage);
                        });
                    }
                }
            )
        },
        handleForumUploadFileExceed(forumMessage, replyToForumMessage, files, uploadFiles) {
            console.log("handleForumUploadFileExceed", forumMessage, files, uploadFiles);
            ElMessage.warning(
                `The limit is 3, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length} totally`
            )
        },
        handleForumBeforeUploadFile(forumMessage, newFile) {
            let maxFileSizeStr = this.parentRef.$appSetting.getGenericValue("FORUM_FILE_MAX_UPLOAD_MB", "4");
            let maxFileSize = parseInt(maxFileSizeStr) * 1024 * 1024;
            console.log("handleForumUploadFileSuccess", forumMessage, newFile, maxFileSize);
            if (maxFileSize < newFile.size) {
                ElMessage.warning(`The file "${newFile.name}" is too big, only less than "${maxFileSizeStr} MB" is acceptable.`);
                return false;
            }

            return true;
        },
        handleForumUploadFileSuccess(forumMessage, replyToForumMessage, response, uploadFile, uploadFiles) {
            console.log("handleForumUploadFileSuccess", forumMessage, response, uploadFile, uploadFiles);
            if (response.result && response.code === "200") {
                forumMessage.fileList = forumMessage.fileList || [];
                let fileObj = response.result;
                fileObj.uid = uploadFile.uid;
                forumMessage.fileList.push(fileObj);
                if (replyToForumMessage) this.buildFileListPreviewComponent(forumMessage, replyToForumMessage);
            } else {
                ElMessage.warning(response.message)
                let pos = uploadFiles.findIndex(e => e === uploadFile);
                if (pos >= 0) forumMessage.fileList.splice(pos, 1)
            }
        },
        reviewFile(fileObj) {
            downloadFile("GET", `file/forum/getFileByPath?p=${fileObj.p}`, null, { enablePreview: true }).catch((err) => {
                console.log("reviewFile", err);
            });
        },
        topForumMessage(index, forumMessage, forumMessageList, top) {
            // twice to confirm to delete
            let warning = `Are you sure to ${top ? "top" : "untop"} this post?`;
            this.parentRef.$confirm(warning, "Prompt", {
                type: "warning",
            })
                .then(() => {
                    putData(`${this.controllerUrl}/message`, `${forumMessage.messageId}/${top ? "topIt" : "untopIt"}`).then((res) => {
                        if (res.result && res.code === "200") {
                            this.parentRef.$message.success("Operating successfully");

                            if (top) {
                                forumMessage.isTop = true;
                                let arr = forumMessageList.splice(index, 1);
                                forumMessageList.splice(0, 0, arr[0]);
                            }
                            else {
                                this.loadForumData();
                            }
                        } else {
                            this.parentRef.$message.error("Operating failed, error message: " + res.message);
                        }
                    });
                })
                .catch(() => { });
        },
        async editForumMessage(index, forumMessage) {
            forumMessage.isEditMode = true;
            let res = await getData(`file/message/getFileInfoList/${forumMessage.messageId}`);
            if (res.result && res.code === "200") {
                forumMessage.fileList = res.result;
            }
            forumMessage.startAndEndDate = [forumMessage.startOfferTime, forumMessage.endOfferTime];
            this.forumMessage = forumMessage;
        },
        deleteForumMessage(index, forumMessage, forumMessageList) {
            // twice to confirm to delete, and type reason for deleting
            this.parentRef.$prompt(
                "Are you sure to delete the message? if yes, please type a reason",
                "Prompt",
                {
                    confirmButtonText: "OK",
                    cancelButtonText: "Cancel",
                    inputPattern: /\.*/,
                    inputErrorMessage: "Please type a reason to delete the message",
                }
            ).then(({ value }) => {
                deleteData(
                    `${this.controllerUrl}/message`,
                    `${forumMessage.messageId}/delete?reason=${value}`
                ).then((res) => {
                    if (res.result && res.code === "200") {
                        forumMessageList.splice(index, 1);
                        let rootMessage = this.forumMessageList.find(e => forumMessage.rootMessageId === e.messageId);
                        if (rootMessage) rootMessage.repliedCount = rootMessage.repliedCount - 1;
                    } else {
                        this.parentRef.$message.error("Delete failed, error message: " + res.message);
                    }
                });
            })
                .catch(() => { });
        },
        showUserPostCard(forumMessage) {
            if (forumMessage.anonymous) return;
            let showWidth = "100%";
            if (!this.inMiniScreen) {
                let windowWidth =
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth;

                let widthPercentage = 0.6;
                showWidth = `${windowWidth * widthPercentage}px`;
            }
            this.parentRef.$msgbox({
                title: `User Card for ${forumMessage.studentName}`,
                message: h(UserPostcard, { userId: forumMessage.studentId, isForAdmin: this.isForAdmin, userRef: this.userRef }),
                showConfirmButton: false,
                showCancelButton: false,
                cancelButtonText: "Close",
                dangerouslyUseHTMLString: true,
                center: true,
                draggable: true,
                customStyle: `width:${this.isForAdmin ? showWidth : "auto"};`,
            });
        },
        chooseForumMessageAsBestAns(index, forumMessage, repliedMessageItem) {
            // twice to confirm to delete
            let warning = `Are you sure to choose this post as best answer?`;
            this.parentRef.$confirm(warning, "Prompt", {
                type: "warning",
            })
                .then(() => {
                    putData(`${this.controllerUrl}/message/${forumMessage.messageId}`, `elect/${repliedMessageItem.messageId}`).then((res) => {
                        if (res.result && res.code === "200") {
                            this.parentRef.$message.success("Operating successfully");
                            forumMessage.bountyStatus = 4;
                            repliedMessageItem.isBestAnswer = true;
                        } else {
                            this.parentRef.$message.error("Operating failed, error message: " + res.message);
                        }
                    });
                })
                .catch(() => { });
        },
        getAtSomeoneName(repliedMessageList, repliedMessage) {
            let parentId = repliedMessage.parentMessageId;
            let parentMessage = repliedMessageList.find(e => e.messageId == parentId);
            if (parentMessage) {
                if (parentMessage.anonymous) return this.anonymousDisplayName;
                else parentMessage.studentName;
            }

            return repliedMessage.quoteObj.sn;
        },
        tryReplaceURL(forumMessage) {
            let message = forumMessage.message || '';
            message = (message + " ").newLineReplace();
            let httpPos = message.indexOf("http://") >= 0;
            let httpsPos = message.indexOf("https://") >= 0;
            let pos = httpPos >= 0 ? httpPos : httpsPos;
            if (pos >= 0) {
                message = message.replace(/((http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,6}|:[0-9]{3,4})\b([-a-zA-Z0-9@:%_+.~#?&//=]*))\s{1}/mg, "<a href='$1' target='_blank'>$1</a> ")
            }

            return message;
        },
        getSecondsLeft(forumMessage) {
            let endOfferDate = Date.parse(forumMessage.endOfferTime);
            if (endOfferDate) {
                let left = endOfferDate - Date.now();
                console.log("getSecondsLeft", forumMessage.endOfferTime, endOfferDate, Date.now(), left);
                return left;
            }
            return -1;
        },
        onCountdownEnd(forumMessage) {
            console.log("onCountdownEnd", forumMessage);
            forumMessage.expiry = true;
        },
        transformSlotProps(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
                if (value)
                    formattedProps[key] = value < 10 ? `0${value}` : String(value);
                else
                    formattedProps[key] = String(value);
            });

            return formattedProps;
        },
    },
}
</script>

<style>
.el-upload--text {
    width: auto !important;
    height: auto !important;
    border-width: 0px;
}

.el-avatar>img {
    margin: 0 auto;
}

.forum-message-container {
    padding: 0;
    margin: 0;
}

.forum-message-container .message-item {
    padding: 10px 0;
}

.forum-message-container .message-item .message-item-container {
    display: flex;
}

.forum-message-container .message-item .message-item-container .author-thumbnail {
    margin-right: 10px;
}

.forum-message-container .message-item .message-item-container .item-content {
    margin-top: 5px;
    padding: 0px;
}

.forum-message-container .message-item .message-item-container .item-content .message-header {
    padding-bottom: 2px;
}

.forum-message-container .message-item .message-item-container .item-content .message-header .author {
    margin-right: 10px;
}

.forum-message-container .message-item .message-item-container .item-content .message-header .time {
    font-size: 80%;
    color: #606060;
}

.forum-message-container .message-item .message-item-container .item-content .message-header .top {
    font-size: 80%;
    margin-left: 10px;
}

.forum-message-container .message-item .message-item-container .item-content .message-header .bounty {
    font-size: 80%;
    margin-left: 10px;
}

.forum-message-container .message-item .message-item-container .item-content .message-content {
    line-height: 1.3rem;
}

.forum-message-container .message-item .message-item-container .item-content .message-action-buttons {
    margin-top: 10px;
}

.forum-message-container .message-item .message-item-container .item-content .message-action-buttons a {
    cursor: pointer;
    margin-right: 10px;
}

.forum-message-container .message-item .message-item-container .item-content .message-action-buttons .count {
    margin-right: 10px;
}

.forum-message-container .message-item .message-item-container .item-content .message-action-buttons .admin-operator {
    color: red;
    font-size: 0.9rem;
    border-radius: 12px;
    border: 1px solid #000;
    padding: 2px 10px;
}

.reply-button-area {
    padding-left: 59px;
    padding-top: 10px;
}

.forum-message-buttons-panel {
    margin-top: 6px;
    display: flex;
}

.forum-message-comments {
    width: 20%;
    text-align: left;
    line-height: 42px;
}

.forum-message-comments .at {
    text-decoration: underline dotted;
}

.forum-message-buttons {
    text-align: right;
    width: 80%;
}

.reply-message-container {
    margin: 10px 0 10px 56px;
}

.forum-message-dialog-header {
    display: inline-flex;
    flex-direction: row;
    margin-top: -42px;
    padding: 20px 0 10px;
    margin-right: 18px;
}

.forum-message-dialog-header .dialog-header-title {
    font-size: 120%;
    margin-right: 10px;
    padding: 10px 0;
    font-weight: normal;
}

.show-more-message {
    margin-top: 10px;
    cursor: pointer;
    display: block;
    text-align: center;
    color: blue;
    font-size: 120%;
}

.no-more-message {
    margin-top: 10px;
    text-align: center;
    color: gray;
    font-size: 80%;
}

.forum-bounty-panel {
    display: flex;
    margin-bottom: 10px;
}

.forum-bounty-panel .date-range {
    margin: 0;
}

.forum-bounty-panel .bounty-input {
    margin-left: 20px;
}

.uploaded-file-panel {
    margin-top: 10px;
}

.message-file-list-container {
    margin-top: 5px;
    margin-bottom: 5px;
}

.message-file-list-header {
    line-height: 32px;
    font-size: 1.05rem;
    color: green;
}

.message-file-list {
    display: flex;
}

.message-file-list .message-file-item {
    margin-right: 20px;
}

.message-file-list .message-file-item .file-icon img {
    max-width: 64px;
    max-height: 64px;
    display: block;
    margin: 0 auto;
}

.message-file-list .message-file-item .file-title {
    margin-top: 5px;
    text-align: center;
    font-size: 0.8rem;
}

.uploaded-file-preview {
    z-index: 199;
    position: relative;
    margin: 2px 0px 0px 0px;
}
</style>