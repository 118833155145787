import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import {
  Search,
  Printer,
  Download,
  Upload,
  Edit,
  Delete,
  CircleCloseFilled,
  CircleCheckFilled,
  Finished,
  CircleClose,
  CollectionTag,
  Position,
  Paperclip,
  Plus,
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  User,
  UserFilled,
  Back,
  CirclePlusFilled,
  HomeFilled,
  Expand,
  Fold,
  Money,
  Reading,
  Postcard,
  Setting,
  CaretBottom,
  CaretLeft,
  CaretRight,
  CaretTop,
  Key,
  Top,
  Bottom,
  Calendar,
  Management,
  Notebook,
  Document,
  Lock,
  Unlock,
  Discount,
  Ticket,
  View,
  Menu,
  Close,
  Histogram,
  House,
  Cloudy,
  Message,
  Tickets,
  Coin,
  Loading,
  Monitor,
  DocumentCopy,
  Minus,
  CloseBold,
  ScaleToOriginal,
  Select,
  DataLine,
  Bell,
  BellFilled,
  PictureFilled,
  CameraFilled,
  DArrowRight,
  ChatLineSquare,
  UploadFilled,
  MuteNotification,
  Check,
} from "@element-plus/icons";

export default (app) => {
  app.component("Search", Search);
  app.component("Printer", Printer);
  app.component("Download", Download);
  app.component("Upload", Upload);
  app.component("Edit", Edit);
  app.component("Delete", Delete);
  app.component("CircleCloseFilled", CircleCloseFilled);
  app.component("CircleCheckFilled", CircleCheckFilled);
  app.component("Finished", Finished);
  app.component("CircleClose", CircleClose);
  app.component("CollectionTag", CollectionTag);
  app.component("Position", Position);
  app.component("Paperclip", Paperclip);
  app.component("Plus", Plus);
  app.component("ArrowDown", ArrowDown);
  app.component("ArrowUp", ArrowUp);
  app.component("ArrowLeft", ArrowLeft);
  app.component("ArrowRight", ArrowRight);
  app.component("User", User);
  app.component("UserFilled", UserFilled);
  app.component("Back", Back);
  app.component("CirclePlusFilled", CirclePlusFilled);
  app.component("HomeFilled", HomeFilled);
  app.component("Expand", Expand);
  app.component("Fold", Fold);
  app.component("Money", Money);
  app.component("Reading", Reading);
  app.component("Postcard", Postcard);
  app.component("Setting", Setting);
  app.component("CaretBottom", CaretBottom);
  app.component("CaretLeft", CaretLeft);
  app.component("CaretRight", CaretRight);
  app.component("CaretTop", CaretTop);
  app.component("Key", Key);
  app.component("Top", Top);
  app.component("Bottom", Bottom);
  app.component("Calendar", Calendar);
  app.component("Management", Management);
  app.component("Notebook", Notebook);
  app.component("Document", Document);
  app.component("Lock", Lock);
  app.component("Unlock", Unlock);
  app.component("Discount", Discount);
  app.component("Ticket", Ticket);
  app.component("View", View);
  app.component("Menu", Menu);
  app.component("Close", Close);
  app.component("Histogram", Histogram);
  app.component("House", House);
  app.component("Cloudy", Cloudy);
  app.component("Message", Message);
  app.component("Tickets", Tickets);
  app.component("Coin", Coin);
  app.component("Loading", Loading);
  app.component("Monitor", Monitor);
  app.component("DocumentCopy", DocumentCopy);
  app.component("Minus", Minus);
  app.component("CloseBold", CloseBold);
  app.component("ScaleToOriginal", ScaleToOriginal);
  app.component("Select", Select);
  app.component("DataLine", DataLine);
  app.component("Bell", Bell);
  app.component("BellFilled", BellFilled);
  app.component("PictureFilled", PictureFilled);
  app.component("CameraFilled", CameraFilled);
  app.component("DArrowRight", DArrowRight);
  app.component("ChatLineSquare", ChatLineSquare);
  app.component("UploadFilled", UploadFilled);
  app.component("MuteNotification", MuteNotification);
  app.component("Check", Check);
  app.use(ElementPlus);
};
