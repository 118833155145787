<script>
import { deleteData, putData } from "../../service/api/index";
import LessonForum from "../../views/common/LessonForum.vue";
import { h } from "vue";

export default {
    props: {
        isForAdmin: Boolean,
    },
    data() {
        return {
            showSessionExpiryAlert: false,
            openForumList: {},
        };
    },
    async created() {
        window.addEventListener("resize", this.resizeWindowHandler);
        this.resizeWindowHandler();

        this.$emitter.on("SESSION_EXPIRED_NOTIFICATION", (arg) => {
            console.log("received event:", "SESSION_EXPIRED_NOTIFICATION", arg);
            this.handleCommand("logOut");
        });

        this.$emitter.on("OPEN_FORUM", (arg) => {
            console.log("received event:", "OPEN_FORUM", arg);
            this.handleCommand("OPEN_FORUM", arg);
        });

        this.$emitter.on("SIGN_OUT", (arg) => {
            console.log("received event:", "SIGN_OUT", arg);
            this.handleCommand("logOut");
        });
    },
    mounted() {
        this.regularCheckLoginStatus();
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeWindowHandler);
    },
    computed: {
        inMiniScreen() {
            return this.$store.state.inMiniScreen;
        },
    },
    methods: {
        handleCommand(command, args) {
            if (command == "logOut") {
                deleteData("/login").then((res) => {
                    console.log("sign out", res);
                    if (res.result && res.code === "200") {
                        this.$message.success("Sign out successfully");
                        console.log("current user", this.$user.currentUser());
                        this.$user.signOut();
                        this.$router.push("/login");
                    } else {
                        this.$message.error("Sign out failed, error message: " + res.message);
                    }
                });
            }
            else if (command == "OPEN_FORUM") {
                let forumId = args[0];
                if (!this.openForumList[forumId]) {
                    this.openForumList[forumId] = true;
                    this.showLessonForum(forumId);
                }
            }
        },
        showLessonForum(forumId) {
            this.refreshReadCount(forumId);
            let showWidth = "100%";
            if (!this.inMiniScreen) {
                let windowWidth =
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth;

                let widthPercentage = 0.6;
                showWidth = `${windowWidth * widthPercentage}px`;
            }

            let lessonForumCom = LessonForum;
            let lessonForumElm = h(
                lessonForumCom,
                {
                    forumId: forumId,
                    isForAdmin: this.isForAdmin,
                    userRef: this.$user,
                    parentRef: this,
                },
            );

            this.$msgbox({
                title: "",
                message: lessonForumElm,
                showConfirmButton: false,
                showCancelButton: false,
                cancelButtonText: "",
                dangerouslyUseHTMLString: true,
                center: false,
                draggable: !this.inMiniScreen,
                customStyle: `width:${showWidth};`,
            }).then(() => {
                console.log("this.$msgbox, then");
                this.openForumList[forumId] = false;
            }).catch(() => {
                console.log("this.$msgbox, catch");
                this.openForumList[forumId] = false;
            }
            );
        },
        refreshReadCount(forumId) {
            // refresh read count
            putData(
                "/forum",
                `${forumId}/refreshReadCount`,
                {},
                {
                    isShowLoading: false,
                }
            );
        },
        regularCheckLoginStatus() {
            var _this = this;
            setInterval(() => {
                if (!_this.$user.isValid()) {
                    this.showSessionExpiryAlert = true;
                } else {
                    this.showSessionExpiryAlert = false;
                }
            }, 1000);
        },
        resizeWindowHandler() {
            let inMiniScreen = false;
            let windowWidth =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            let windowHeight =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;
            if (windowWidth < 760) {
                inMiniScreen = true;
            }
            let arr = [inMiniScreen, windowWidth, windowHeight];
            this.$store.commit("handleWindowSize", arr);

            this.$nextTick(() => {
                this.$emitter.emit("WINDOW_SIZE_CHANGE", arr);
            });
            console.log("Common.resizeWindowHandler", arguments, arr);
        },
    },
};
</script>
